import ApiService from "@/core/services/api.service";

export const GET_SUPPLIER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`bill-get-supplier`, {
      supplier: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_BARCODE = (pv_number, id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`update-barcode`, {
      barcode: pv_number,
      record_id: id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_BILL_DETAIL = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`bills/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_PV = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`bills-payment-record/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_BILL = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`bills-filter`, {
      id: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_BILL = (supplier_filter, paymentby, range, search, selectstatus,currentPage) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`bills`, {
      supplier: supplier_filter,
      date: range,
      paymentby: paymentby,
      search,
      selectstatus,
      current_page:currentPage
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_PV = (supplier_filter, paymentby, range, search,currentPage,searchtype) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`bills-payment-record`, {
      supplier_id: supplier_filter,
      paymentby: paymentby,
      date: range,
      search,
      current_page:currentPage,
      searchtype
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const SEND_PV = (params, id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`update-barcode`, {
      barcode: params,
      record_id: id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// export const GET_BILL = (table, params) => {
//   return new Promise((resolve, reject) => {
//     ApiService.setHeader();
//     ApiService.put(`pos/table/${table}/finish`, params)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
GET_BILL;
export const CREATE_BILL = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("bills", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_RECORD_BILL = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("bills-payment-record", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
