<template>
  <v-sheet class="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="12" class="py-0">
        <v-row>
          <!-- {{ supplierLists }} -->
          <!-- Drag and drop oprations -->
          <v-col md="12" class="pb-0">
            <v-row>
              <v-layout class="">
                <v-col md="12" class="py-0">
                  <div class="d-flex justify-center flex-wrap pb-1 pt-3 w-100">
                    <VCard @click="statusSelect(all)" class="d-flex align-center me-4 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #90caf9; width: 19%">
                      <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-3">
                        <span class="text-h4 blue--text font-weight-bold">
                          {{ all }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">All</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 blue--text mb-0" style="font-weight: 600">
                          {{ formatMoney(total_bills) }}
                        </h4>
                      </div>
                    </VCard>

                    <VCard @click="statusSelect(final_paid)" class="d-flex align-center me-4 mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid #95c999; width: 19%">
                      <VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="me-2">
                        <span class="text-h4 green--text font-weight-bold">
                          {{ paids }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Final Paid</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 green--text mb-0" style="font-weight: 600">
                          {{ formatMoney(paid_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    <VCard @click="statusSelect(pending)" class="d-flex align-center mb-2 pa-2 flex-grow-1"
                      style="border: 0.5px solid orange; width: 19%">
                      <VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-2">
                        <span class="text-h4 orange--text font-weight-bold">
                          {{ pendings }}
                        </span>
                      </VAvatar>
                      <h3 class="text-h6 mb-0" style="font-weight: 600">Final Pending</h3>
                      <VSpacer />
                      <div>
                        <h4 class="text-h4 pe-3 orange--text mb-0" style="font-weight: 600">
                          {{ formatMoney(pending_amount) }}
                        </h4>
                      </div>
                    </VCard>
                    <!-- <VCard class="d-flex align-center mb-2 pa-2 flex-grow-1" style="border: 0.5px solid #ead0a7; width: 19%">
            <VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-2">
              <v-icon color="orange" size="34" dark> mdi-cart-check </v-icon>
            </VAvatar>
            <h3 class="text-h6 mb-0" style="font-weight: 600">Sent for approval</h3>
            <VSpacer />
            <div>
              <h4 class="text-h4 pe-3 orange--text" style="font-weight: 600">
                {{ 1 }}
              </h4>
            </div>
          </VCard> -->
                  </div>
                </v-col>
              </v-layout>
            </v-row>
            <!-- 2nd row Add Product button -->
            <div class="d-flex flex-row align-center justify-content-between py-2">
              <!-- <h1 class="custom-header-blue-text m-0">Bills</h1> -->
              <PageHeaderCount moduleType="bill" :dataLoading="dataLoading" :statusList="status_list" allkey="all_bills"
                countkey="status_count" v-on:saveAddress="updateAddressPerson" :stat="selectstatus"></PageHeaderCount>
              <v-col md="3" class="py-0 pr-0 mr-2">
                <DateRangePicker hide-details :hideTopMargin="true" v-on:change="searchrange()"
                  v-on:clear="searchranges()" :minDate="range && Array.isArray(range) && range[0]" v-model="range"
                  :disabled="pageLoading" :loading="pageLoading" id="item-sale-duration" placeholder="Date Range"
                  class="mr-2" clearable></DateRangePicker>
              </v-col>
              <div class="mr-3">
                <AutoCompleteInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                  item-text="company_name" item-value="company_name" v-model="supplier_filter"
                  v-on:change="searchsupplier()" style="width: 150px" placeholder="Select supplier">
                </AutoCompleteInput>
              </div>
              <div class="mr-3">
                <AutoCompleteInput hide-details :hideTopMargin="true" :items="paymentModeList" id="segment"
                  style="width: 150px" item-value="value" v-model="paymentby" v-on:change="paymentBy()"
                  placeholder="Payment By"></AutoCompleteInput>
              </div>

              <div>
                <!-- <v-btn color="blue darken-4" depressed tile class="mr-2 white--text"
                                    :to="{ name: 'purchase-order-create', query: { t: new Date().getTime() } }">
                                    New Purchase Order
                                </v-btn> -->
                <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="blue darken-4" depressed tile v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                          class="white--text">
                          <v-icon>edit_note</v-icon>
                        </v-btn>
                      </template>
                      <span>Columns arrangement and visibility</span>
                    </v-tooltip>
                  </template>
                  <v-list nav dense>
                    <v-list-item draggable v-on:dragstart="dragStart(index)" v-on:dragend="dragEnd"
                      v-on:dragover="dragOver(index)" v-on:mousedown="mouseEvent('mousedown')"
                      v-on:mouseup="mouseEvent('mouseup')" v-for="(item, index) in defaultColDefs"
                      :key="item.field + '_' + index" class="flex-column cursor-move">
                      <div class="d-flex flex-row align-center">
                        <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>
                    <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                        <v-icon>drag_indicator</v-icon>
                      </div>
                      <v-divider v-if="index < customThead.length - 1" class="mt-2 mb-0"
                        style="width: 100%"></v-divider>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="pb-0 pt-1">
        <v-simple-table fixed-header class="bt-table product-simple-table">
          <template v-slot:default>
            <!-- table titles -->

            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th v-for="(product_h, index) in customThead" :key="'$_' + product_h.title + '_' + index + 1">
                  <span v-if="product_h.field === '#'">
                    <v-checkbox v-model="productTableSelectAll" v-on:change="checkAll" hide-details color="#0d47a1"
                      class="ma-0 pa-0"></v-checkbox>
                  </span>
                  <span v-else>
                    {{ product_h.headerName }}
                  </span>
                </th>
              </tr>
            </thead>

            <!-- table body -->

            <tbody v-if="!pageLoading">
              <template v-if="productList?.length">
                <tr v-for="(row, bkey) in productList" :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="product-listing-tr" :key="'k_' + bkey">
                  <td style="border-right: none !important" link v-on:click="routeToDetail(row.uuid)"
                    v-for="(data, idx) in customThead" :key="data.field + '_' + idx + '_' + idx * 5"
                    :width="data.field == 'actions' ? '60px' : '' ? '20px' : ''">
                    <ValueTemplate v-if="data.key == 'uom_primary'" :image="data.key === 'profile_logo'"
                      :title="data.title" :decoration="data.decoration" >
                      <template #value>
                        <div v-if="data.key === 'uom_primary' ||
                          data.key === 'uom_secondary' ||
                          data.key === 'reorder'
                          ">
                          <v-chip :class="'mr-0 pa-0 px-2' + ' ' + data.decoration.customClass"
                            :outlined="data.decoration.outlined" :color="data.decoration.bgColor"
                            :text-color="data.decoration.textColor" small>
                            <span v-if="data.field === 'reorder'">{{ row["reorder"] }}</span>
                            <span v-else>{{ row["unit_secondary"] }}</span>
                          </v-chip>
                          {{ data }}
                          <span v-if="data.field === 'uom_secondary'">
                            {{ row.primary_sales_uom }} </span>&nbsp;
                          <span v-if="data.field === 'Reorder'">{{ row["uom_primary"] }}</span>
                          <span v-else>{{ row[data.key] }}</span>
                        </div>
                      </template>
                    </ValueTemplate>
                    <div v-else-if="data.field === 'key'" @click.stop class="d-flex align-center">
                      <v-checkbox v-model="row['key']" hide-details color="#0d47a1" class="ma-0 pa-0"></v-checkbox>
                    </div>

                    <!-- Action buttons -->
                    <div v-else-if="data.field === 'actions'" style="border-right: none !important" width="100">
                      <!-- order icon -->

                      <!-- edit icon -->
                      <!-- <v-tooltip top content-class="custom-top-tooltip">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn depressed fab dark x-small class="mx-2" color="green"
                                                        v-bind="attrs" v-on="on"
                                                        @click.stop.prevent="routeToUpdate(row.uuid)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Edit</span>
                                            </v-tooltip> -->

                      <!-- delete key -->
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on"
                            @click.stop.prevent="deleteConfirm(row)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>

                    <div v-else-if="data.field === 'activated'" style="border-right: none !important" width="100">
                      <v-switch v-model="row[data.key]" class="mt-0"
                        v-on:click.prevent.stop="() => activeInactive(row[data.key], row.id)" color="green"></v-switch>
                    </div>

                    <div v-else-if="data.field === 'bill'" style="border-right: none !important" width="100">
                      <v-chip small outlined label color="blue">{{ row.bill }}</v-chip>
                    </div>
                    <div v-else-if="data.field === 'details'" style="border-right: none !important" width="100">
                      <div>
                        Ref: <span v-if="row.ref_number">{{ row.ref_number }}</span>
                        <span v-else>No Ref</span>
                      </div>

                      <div>
                        Bill: <span>{{ formatedateTime(row.bill_date) }}</span>
                      </div>
                    </div>
                    <div v-else-if="data.field === 'contact_person'" style="border-right: none !important" width="100">
                      <div v-if="!row.payment_mode">
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                          <b style="text-transform: uppercase;">{{ row.supplier_contact_details }}</b>
                        </div>
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                          <span @click.stop.prevent="copyText('maxwell@gmial.com')" v-if="row.contact_email">{{ row.contact_email }}</span>
                          <span v-else>No Email</span>

                        </div>
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                          <span @click.stop.prevent="copyText('+65 1234 5678')" v-if="row.contact_phone">{{
                            row.contact_phone
                          }}</span>
                                                        <span v-else>No Phone</span>

                        </div>
                      </div>
                      <div v-else>-</div>
                    </div>
                    <div v-else-if="data.field === 'payment_to'" style="border-right: none !important" width="100">
                      <div v-if="!row.payment_mode">
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-home-city-outline</v-icon>
                          <b style="text-transform: uppercase;">{{ row.supplier }}</b>
                        </div>
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                          <b style="text-transform: uppercase;">{{ row.supplier_contact_details }}</b>
                        </div>
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                          <span @click.stop.prevent="copyText('john@gmial.com')" v-if="row.email">{{
                            row.email
                          }}</span>
                                                        <span v-else>No Email</span>

                        </div>
                        <div>
                          <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                          <span @click.stop.prevent="copyText('+65 1234 5678')" v-if="row.phone">{{
                            row.phone
                          }}</span>
                                                        <span v-else>No Phone</span>

                        </div>
                      </div>
                      <div v-else>
                       <b style="text-transform: uppercase;"> {{ row.payment_mode }}</b> 
                      </div>
                    </div>
                    <div v-else-if="data.field === 'amount'" style="border-right: none !important" width="100">
                      <div>
                        <span class="font-weight-bold">{{ formatMoney(row.amount) }}</span>
                      </div>

                      <v-chip v-if="row.status == 'Pending'" small outlined label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'orange'">Final Pending</v-chip>
                      <v-chip v-else-if="row.status == 'Paid'" small outlined label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'green'">Final Paid</v-chip>
                    </div>
                    <div v-else-if="data.field === 'primary'" style="border-right: none !important" width="100">
                      <v-chip v-if="row.status == 'Paid'" small outlined label color="success">Bank</v-chip>
                      <ValueTemplate v-else v-model="row.payment_voucher" title="Payment Mode">
                      </ValueTemplate>
                    </div>
                    <div v-else-if="data.field === 'secondary'" style="border-right: none !important" width="100">
                      <v-chip v-if="row.payment_two" small outlined label color="success">{{
                        row.payment_two
                      }}</v-chip>
                      <div v-else class="simple-table-text">No Payment</div>
                    </div>
                    <div v-else-if="data.field === 'po_status'" style="border-right: none !important" width="100">
                      <v-chip v-if="row.status == 'Pending'" small outlined label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'orange'">Final Pending</v-chip>
                      <v-chip v-else-if="row.status == 'Paid'" small outlined label
                        :color="row.amountStatus == 'Completed' ? 'success' : 'green'">Final Paid</v-chip>
                    </div>
                    <div v-else-if="data.field === 'bill'" style="border-right: none !important" width="100">
                      <v-chip v-if="row.bill" small outlined label color="success">{{
                        row.bill
                      }}</v-chip>
                      <div v-else class="simple-table-text">Not Billed</div>
                    </div>
                    <div v-else-if="data.field === 'added_at'" style="border-right: none !important" width="100">
                      <div>
                        <v-chip small outlined label color="blue">{{ row.created_by }}</v-chip>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-calendar</v-icon>
                          {{ formatedateTime(row.created_at) }}
                          <VTooltip activator="parent"> Created date </VTooltip>
                        </div>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-clock</v-icon>
                          {{ formateTime(row.created_at) }}
                          <VTooltip activator="parent"> Created Time </VTooltip>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="data.field === 'modified_at'" style="border-right: none !important" width="100">
                      <div>
                        <!-- <v-chip small outlined label color="blue"></v-chip> -->
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-calendar</v-icon>
                          {{ formatedateTime(row.last_modified_at) }}
                          <VTooltip activator="parent"> Created date </VTooltip>
                        </div>
                      </div>
                      <div class="text-sm mt-1">
                        <div>
                          <v-icon start size="16">mdi-clock</v-icon>
                          {{ formateTime(row.last_modified_at) }}
                          <VTooltip activator="parent"> Created Time </VTooltip>
                        </div>
                      </div>
                    </div>

                    <!-- Name -->
                    <div v-else>
                      <div v-for="(value, key, inddx) in row[data.key]" :key="'$_' + value + '_' + inddx + '_' + key">
                        <span style="font-weight: 700">{{ key.toUpperCase() }}&nbsp;</span>
                        <ValueTemplate v-model="row['name'][key]" :title="`Name (${data.key.toUpperCase()})`" />
                      </div>
                      <div class="mt-2">
                        {{ row["name_en"] }}
                        {{ row["name_cn"] }}
                        {{ row["name_my"] }}
                        ({{
                          row["unit_description_psu"] +
                          " " +
                          row["primary_sales_uom"] +
                          " " +
                          "X" +
                          " " +
                          row["unit_value"] +
                          " " +
                          "in" +
                          " " +
                          1 +
                          " " +
                          row["secondary_purchase_uom"]
                        }})
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                      Uhh... There are no Bills at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot v-else>
              <tr v-for="idr in 9" :key="idr">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>

        <v-layout v-if="productList?.length" class="light-border-top product-table-bottom mt-0">
          <v-flex md6 align-self-center>
            <label class="btx-label p-4">
              Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label>
          </v-flex>
          <v-flex md6 align-self-center>
            <v-pagination v-model="currentPage" :length="pageLimit" :total-visible="5"
              color="blue darken-4"></v-pagination>
          </v-flex>
        </v-layout>
        <DeleteTemplate type="Bills" :delete-text="deleteText" delete-note="All bills will also be deleted."
          :delete-dialog="deleteDialog" :delete-endpoint="deleteEndpoint" v-on:close="deleteDialog = false"
          v-on:delete:success="getItems()">
        </DeleteTemplate>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
// import { GET_PO } from "@/core/lib/pos.lib";
import { SearchEventBus } from "@/core/lib/search.lib";

import { GET_BILL } from "@/core/lib/bill.lib";
import { formatMoney } from "accounting-js";

import moment from "moment-timezone";
import DateRangePicker from "@/view/components/DateRangePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { GET_PAYMENT } from "@/core/lib/pos.lib";
import { PATCH, QUERY,POST } from "@/core/services/store/request.module";
import PageHeaderCount from "@/view/components/PageHeaderCount.vue";

import ValueTemplate from "@/view/components/ValueTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
// import { All_PRPDUCTS } from "@/core/lib/product.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import PosMixin from "@/core/mixins/pos.mixin";
import { filter, map } from "lodash";

export default {
  name: "purchase-listing",
  mixins: [PosMixin],

  title: "Listing purchase",
  data() {
    return {
      productList: [],
      defaultColDefs: [],
      customThead: [],
      paid_amount: 0,
      paymentModeList: [],
      final_paid: 5,
      paymentby: null,
      range: null,
      all: 0,
      pending: 4,
      totalRows: null,
      supplierLists: [],
      dataLoading: true,
      searchEnabled: false,
      searchParam: null,
      selectstatus: null,
      pending_amount: 0,
      supplier_filter: null,
      total_bills: 0,
      total: 0,
      pageTitle: "Bills",
      initial_paids: 0,
      pendings: 0,
      paids: 0,
      status_list: null,

      products: [
        {
          id: 1,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/4/47/Hamburger_%28black_bg%29.jpg",
          code: "ABC123",
          name: {
            en: "English",
            cn: "Chinese",
          },
          cat: "disposable",
          quantity: 12,
          reorder: 2,
          uom_primary: "CAN",
          uom_secondary: "CTN",
          unit_primary: "300ml",
          unit_secondary: 12,
        },
        {
          id: 2,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "DEF456",
          name: {
            en: "English 2",
            cn: "Chinese 2",
            my: "Myanmar 2",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 10,
          reorder: 1,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 3,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 4,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 5,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 6,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 7,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 8,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
        {
          id: 9,
          status: false,
          image: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg",
          code: "GHI789",
          name: {
            en: "Kannada",
            cn: "German",
            my: "Duch",
          },
          cat: "adhoc",
          uom_primary: "PKT",
          uom_secondary: "CTN",
          quantity: 15,
          reorder: 2,
          unit_primary: "400g",
          unit_secondary: 12,
        },
      ],
      productTableHeading: [
        // {
        //   title: "#",
        //   key: "key",
        //   status: true,
        //   decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        // },
        // {
        //     title: "Actions",
        //     key: "actions",
        //     status: true,
        //     decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        // },
        {
          title: "Bill#",
          key: "barcode",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Details",
          key: "detail",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Payment To",
          key: "supplier",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Contact Person",
          key: "contact_person",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Amount/Status",
          key: "status",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#666666",
            textColor: "#000",
            customClass: "",
            outlined: true,
          },
        },
        // {
        //     title: "Payment Modes",
        //     key: "primary",
        //     status: true,
        //     decoration: {
        //         is: true,
        //         type: "chip",
        //         bgColor: "#5cb85c",
        //         textColor: "#fff",
        //         customClass: "",
        //     },
        // },
        // {
        //   title: "PO Status",
        //   key: "po_status",

        //   status: true,
        //   decoration: {
        //     is: true,
        //     type: "chip",
        //     bgColor: "",
        //     textColor: "#fff",
        //     customClass: "blue darken-2",
        //   },
        // },

        {
          title: "Created At",
          key: "created_At",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "#fff",
            customClass: "blue darken-2",
          },
        },
      ],
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      pageLimit: 10,
      showingFrom: 1,
      showingTo: 10,
      totalPages: null,
      currentPage: 1,
      dragOverIndexForProductTableColumns: null,
      dragStartIndexForProdctTableColumns: null,
      productTableSelectAll: false,
    };
  },
  watch: {
    productTableSelectAll(value) {
      this.products.map((_value) => (_value.status = value));
    },
    currentPage() {
      this.getItems();
    },
  },
  methods: {
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getItems();
          });
      });
    },
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.getItems();
    },
    statusSelect(data) {
      this.selectstatus = data;
      this.getItems();
    },
    searchrange() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.date = this.range;
        // this.getItems();
        const date1 = new Date(this.range[0]);
        const date2 = new Date(this.range[1]);

        // var date1 = moment(this.range[1]).format("dd-mm-yyyy")

        // var date2 = moment(this.range[0]).format("dd-mm-yyyy")
        console.log(date1, 'date1')
        console.log(date2, 'date2')

        if (date1 > date2) {


          this.$store.commit(SET_ERROR, [
            { model: true, message: "End Date must be after Start Date" },
          ]);
          return false;
        } else {
          this.getItems();



        }
        // this.getItems();
      }
    },
    searchranges() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.range = null;
        this.getItems();
      }
    },
    paymentBy() {
      this.paymentbys = this.paymentby;
      this.getItems();
    },
    searchsupplier() {
      this.supplier_id = this.supplier_filter;

      this.getItems();
    },
    getSupplier() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "suppliers",
        })
        .then((data) => {
          _this.supplierLists = data;
          // console.log(_this.supplierList)
          //   const supplierList1 = _this.supplierLists.map((item) => {
          //     return {
          //       barcode: item.barcode + " - " + item.first_name,
          //       image: item.supplier_image?.url,
          //       value: item.id,
          //       name: item.first_name,
          //     };
          //   });
          //   _this.supplierList = supplierList1;
          // console.log(_this.supplierList)
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    async getPayment() {
      const data = await GET_PAYMENT();
      this.paymentModeList1 = data;
      const paymentModeList2 = this.paymentModeList1.map((item) => {
        return {
          text: item.payment_type,
          value: item.value,
        };
      });
      this.paymentModeList = paymentModeList2;
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formateTime(param) {
      return moment(param).format("hh:mm A");
    },
    getRowValue(row, data) {
      if (data.key === "profile_logo") {
        return this.getProfileImage(row[data.key]);
      } else {
        return row[data.key];
      }
    },
    activeInactive(data, id) {
      const _this = this;
      console.log(data);
      _this.$store
        .dispatch(PATCH, {
          url: `single-product/${data}/${id}`,
          data: {
            id: id,
            status: Number(data),
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "#0d47a1";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "purchase-order-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    //     routeToDetail(id) {
    //   this.$router.push({
    //     name: "purchase-order-detail",
    //     params: { id },
    //     query: { t: new Date().getTime() },
    //   });
    // },

    // async getProducts() {
    //     const _this = this;

    //     this.pageLoading = true;
    //     try {
    //         const { rows, current_page, showingFrom, showingTo, totalrows, totalPages, status_list } =
    //             await All_PRPDUCTS(
    //                 this.currentPage
    //                 //this.per_page
    //             );
    //         this.productList = rows;
    //         this.total = totalrows;
    //         this.status_list = status_list;
    //         this.totalPages = totalPages;
    //         console.log(this.status_list);

    //         this.currentPage = current_page;
    //         this.showingFrom = showingFrom;
    //         this.showingTo = showingTo;
    //         this.status_list = status_list;

    //         this.totalrows = totalrows;
    //     } catch (error) {
    //         _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //     } finally {
    //         this.pageLoading = false;
    //         this.loadPagination = true;
    //     }
    // },
    async getItems(search = null) {
      try {
        // const form = {
        //   current_page: this.currentPage,
        //   search: this.listingSearch,
        // };
        this.pageLoading = true;
        const {
          data,
          totalPages,
          showingFrom,
          showingTo,
          all,
          totalRows,
          paid,
          pending,
          pending_amount,
          paid_amount,
          total_bills,
          status_list,
          theads
        } = await GET_BILL(
          this.supplier_filter,
          this.paymentby,
          this.range,
          search,
          this.selectstatus,
          this.currentPage
        );

        // const { data, totalPages, showingFrom, showingTo, all,initial_paid,final_paid,pending } = await GET_PO(form);
        this.productList = data;
        console.log(this.purchaseOrder);
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.all = all;
        this.status_list = status_list;
        // this.initial_paids = initial_paid;
        this.paids = paid;
        this.pendings = pending;
        this.paid_amount = paid_amount;
        this.pending_amount = pending_amount;
        this.total_bills = total_bills;
        
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead,'fsdfd');
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    checkAll(e) {
      console.log({ e });
    },
    dragStart(dragStartIndex) {
      this.dragStartIndexForProdctTableColumns = dragStartIndex;
    },
    dragOver(dragOverIndex) {
      this.dragOverIndexForProductTableColumns = dragOverIndex;
    },
    dragEnd() {
      const itemToReplace = this.productTableHeading[this.dragStartIndexForProdctTableColumns];
      const itemToReplaceIndex = this.dragStartIndexForProdctTableColumns;
      const whereToDropIndex = this.dragOverIndexForProductTableColumns;

      this.productTableHeading.splice(itemToReplaceIndex, 1);
      this.productTableHeading.splice(whereToDropIndex, 0, itemToReplace);

      this.dragStartIndexForProdctTableColumns = null;
      this.dragOverIndexForProductTableColumns = null;
    },
    mouseEvent(eventType) {
      const listItem = document.querySelector(".column-arrangement-list-item");
      if (eventType === "mousedown") {
        console.log({ eventType, listItem });
        listItem.classList.add("cursor-grabbing");
        listItem.classList.remove("cursor-grab");
      } else {
        console.log({ eventType, listItem });
        // listItem.setProperty('cursor', 'grab', 'important');

        listItem.classList.add("cursor-grab");
        listItem.classList.remove("cursor-grabbing");
      }
    },
    dataKey(key, value = null) {
      if (key.includes("(")) {
        if (key.includes(",")) {
          const _key = key.replace("(", "").replace(")", "").replace(" ", ",").toLocaleLowerCase();
          const arr = _key.split(",");
          return { main: arr[0], key: value ? Object.keys(value) : arr.slice(1) };
        } else {
          const _key = key.replace("(", "").replace(")", "").replace(" ", "_").toLocaleLowerCase();
          return _key;
        }
      } else {
        return key.toLocaleLowerCase();
      }
    },
    addOrder({ id }) {
      // this.customerId = id;
      // this.orderDialog = true;
      console.log({ id });
    },
    routeToDetail(row) {
      console.log(row);

      this.$router.push({
        name: "bills-detail",
        params: { id: row },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ uuid, code }) {
      this.deleteText = code;
      this.deleteEndpoint = `bills/${uuid}`;
      this.deleteDialog = true;
    },
  },
  mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getItems(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getSupplier();
    this.getPayment();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    // console.log(this.setSearchBar,'dd')
    this.$store.dispatch(SET_SEARCH_TITLE, "Bills");
    // this.getItems();
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    DeleteTemplate,
    ValueTemplate,
    DateRangePicker,
    AutoCompleteInput,
    PageHeaderCount,
  },

  computed: {
    ...mapGetters(["listingSearch"]),
    modifiedTableHeading() {
      const _tableHadings = this.productTableHeading.filter((heading) => heading.status);
      return _tableHadings;
    },
    dimentions() {
      const screenWidth = screen.width;
      const screenHeight = screen.height;
      return { height: screenHeight, width: screenWidth };
    },
  },
};
</script>

<style scoped>
.product-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.product-table-bottom {
  position: absolute !important;
  width: 100%;
  bottom: 0% !important;
  z-index: 9;
  background-color: #fff;
}

::-webkit-scrollbar {
  height: 5px !important;
}
</style>
